import { useEffect, useRef, useState, memo } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import useTimelineUpdater from "./useTimelineUpdater";
import { t } from "@/utils";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useMount } from "@/hooks/useLifeCycles";
import { EventIcons, EventStringKeys } from "@/enums/events";
import ArrowIcon from "assets/icons/arrow_right.svg?react";
import intervalIconPath2 from "assets/icons/IntervalR.svg";
import UnlockButton from "./UnlockButton";
import EventPlayerModal from "./EventPlayerModal";
import Icon from "@/components/Icon";
import Time from "@/lib/Time";
import { useTransparentize } from "@/theme/utils/transparentize";
import Player from "video.js/dist/types/player";

function Timeline({ onReady }: { onReady: Cb }) {
  const {
    onTimeUpdate,
    startDrag,
    onScroll,
    setScrollableElements,
    video,
    indicator,
    timeline,
    container,
    backgroundSvg,
    playerRef
  } = useTimelineUpdater();
  const [isReadyToBeShown, setIsReadyToBeShown] = useState(false);

  const scrollableContainer = useRef<HTMLDivElement | null>(null);
  const scrollableElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onVideoElement = (el: HTMLVideoElement, player: Player) => {
      el.ontimeupdate = onTimeUpdate;
      video.current = el;
      playerRef.current = player;
    };
    const onTimelineReady = () => {
      setIsReadyToBeShown(true);
    };

    dataSyncEmitter.on("timeline-video-element-creation", onVideoElement);
    dataSyncEmitter.on("timeline-ready", onTimelineReady);
    return () => {
      dataSyncEmitter.off("timeline-video-element-creation", onVideoElement);
      dataSyncEmitter.off("timeline-ready", onTimelineReady);
    };
  }, [onTimeUpdate, playerRef, video]);

  useEffect(() => {
    setScrollableElements({ containerEl: scrollableContainer, scrollEl: scrollableElement });
  }, [setScrollableElements]);

  useMount(onReady);

  const containerBg = useTransparentize("neutral-variant.40", 0.6);
  return (
    <Box
      id="playlist_container"
      overflow="hidden"
      userSelect="none"
      w="100%"
      h="100%"
      sx={{
        ...(isDev && {
          ".vjs-control-bar": {
            opacity: 1,
            top: "20%"
          }
        }),
        ".vjs-loading-spinner": {
          pos: "fixed",
          top: "50vh",
          transform: "translateY(-50%)"
        }
      }}
    >
      <Box transition="opacity 0.2s ease-in-out" opacity={isReadyToBeShown ? 1 : 0}>
        <UnlockButton />
        <Flex
          h="0px"
          overflow="visible"
          pos="absolute"
          bottom="62px"
          left="192px"
          right="32px"
          zIndex={3}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Box borderRadius="80px" px="12" py="4" bg="neutral.99" h="28" minH="28">
            <MetaData />
          </Box>
          <Box bg="neutral.99" minH="66px" w="4px" ref={indicator} />
          <Box bg="neutral.99" minH="12" w="12" borderRadius="100%" />
        </Flex>
        <Flex
          h="54px"
          pos="absolute"
          bottom="22px"
          left="192px"
          right="32px"
          alignItems="center"
          overflowX="hidden"
          zIndex={2}
          bg={containerBg}
          cursor="pointer"
          onMouseDown={startDrag}
          ref={container}
          backdropFilter="blur(16px)"
          justifyContent="center"
          borderRadius="32px"
          id="timeline-container"
        >
          <Box
            ref={scrollableContainer}
            h="54px"
            w="100%"
            zIndex={3}
            pos="absolute"
            overflow="scroll"
            opacity={0}
            onScroll={onScroll}
          >
            <Box ref={scrollableElement} h="100%" w="200%" />
          </Box>
          <Flex h="1px" w="100%" bg="#D9D9D9" zIndex={2} pos="absolute" opacity={0.2} />
          <Box pos="relative" zIndex={1}>
            <Flex
              transform="translateY(-50%)"
              ref={timeline}
              w="0px"
              h="22px"
              pos="absolute"
              left="0px"
              clipPath="inset(-100vw 0 -100vw 0)"
              alignItems="center"
            >
              <Box
                ref={backgroundSvg}
                style={{ backgroundImage: `url("${intervalIconPath2}")` }}
                bgRepeat="repeat-x"
                sx={{ backgroundPositionY: "center" }}
                w="100%"
                height="22px"
                zIndex={1}
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
      <EventPlayerModal />
    </Box>
  );
}

function MetaData() {
  const dateContainer = useRef<HTMLDivElement>(null);
  const [events, setEvents] = useState<CameraEvent[]>([]);
  const [hasDataAvaiable, setHasDataAvaiable] = useState(true);

  useEffect(() => {
    function onMetaTimeUpdate(e: Event) {
      if (!dateContainer.current) return;
      const { detail } = e as CustomEvent<{ time: number; hasDataForTime: boolean }>;
      const date = new Date(detail.time);
      dateContainer.current.innerText = Time.formatDate(date, "HH:mm:ss");
      setHasDataAvaiable(detail.hasDataForTime);
    }
    function updateMetaEvents(e: Event) {
      const { detail } = e as CustomEvent<{ events: CameraEvent[] }>;
      log.timeline("Got event update in timeline: ", detail);
      setEvents(detail.events);
    }

    document.addEventListener("meta-time-update", onMetaTimeUpdate);
    document.addEventListener("meta-events-update", updateMetaEvents);
    return () => {
      document.removeEventListener("meta-time-update", onMetaTimeUpdate);
      document.removeEventListener("meta-events-update", updateMetaEvents);
    };
  }, []);
  return (
    <Flex userSelect="none" alignItems="center" columnGap="0.75rem" h="100%">
      <Text ref={dateContainer} color="neutral.10" variant="label" />
      {!hasDataAvaiable && (
        <Text color="neutral.10" variant="label" display="flex" alignItems="center">
          <Box h="3px" w="3px" borderRadius="100%" bg="neutral.10" mr="0.75rem" as="span" />
          {t("notAvailable")}
        </Text>
      )}
      {events.length > 0 && <Box h="3px" w="3px" borderRadius="100%" bg="neutral.10" />}
      {events.map((event) => {
        return (
          <Flex
            as="button"
            key={event.uniqueId}
            alignItems="center"
            columnGap="4"
            onClick={() => dataSyncEmitter.emit("select-timeline-event", { uniqueId: event.uniqueId })}
          >
            <Icon icon={EventIcons[event.type]} fill="neutral.10" size="20" />
            <Text color="neutral.10" variant="label">
              {t(EventStringKeys[event.type])}
            </Text>
            <Icon icon={ArrowIcon} />
          </Flex>
        );
      })}
    </Flex>
  );
}

export default memo(Timeline);
