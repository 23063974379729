import { createContext, useContext, useMemo } from "react";
import { usePresenceKeeper } from "@/modules/app/Presence";
import { useDevices } from "@/store/devices";
import { getDeviceByObjectId } from "@/store/devices/selectors";
import { isStationOffline } from "@/utils/isStationOffline";
import { useActivityLog } from "viewer/store/activityLog";
import { useViewer } from "viewer/store/viewer";

const selectedEventDefaults = {
  event: null,
  previewUrl: undefined,
  isFinished: false,
  jid: undefined,
  device: undefined,
  isOngoingAndLive: false,
  isOngoingAndOffline: false,
  isPlayerEvent: false
};

function _useSelectedEvent() {
  const { events, selectedEventId, playerEvent } = useActivityLog((s) => ({
    events: s.events,
    selectedEventId: s.selectedEventId,
    playerEvent: s.playerEvent
  }));
  const selectedEvent = playerEvent || events.find((e) => e.uniqueId === selectedEventId);

  const previews = useViewer((s) => s.cameraPreviews);
  const device = useDevices((s) => getDeviceByObjectId(selectedEvent?.cameraStation.objectId)(s));

  const presences = usePresenceKeeper();
  const presence = device?.jid ? presences[device.jid] : "unavailable";
  const cameraPreview = device?.jid ? previews[device.jid] : null;

  return useMemo(() => {
    const isOngoingAndLive = Boolean(!selectedEvent?.end) && !isStationOffline(presence);
    const isOngoingAndOffline = Boolean(!selectedEvent?.end) && isStationOffline(presence);
    if (!selectedEvent) return selectedEventDefaults;

    const isFinished = Boolean(selectedEvent.end);
    return {
      event: selectedEvent,
      previewUrl: isOngoingAndLive ? cameraPreview?.image?.url : selectedEvent.thumbnailUrl,
      isFinished,
      jid: device?.jid,
      device,
      isOngoingAndLive,
      isOngoingAndOffline,
      isPlayerEvent: Boolean(playerEvent)
    };
  }, [cameraPreview?.image?.url, device, playerEvent, presence, selectedEvent]);
}

const SelectedEventCtx = createContext<ReturnType<typeof _useSelectedEvent>>(selectedEventDefaults);

export const useSelectedEvent = () => useContext(SelectedEventCtx);

export default function SelectedEventProvider({ children }: { children: any }) {
  const selectedEvent = _useSelectedEvent();
  return <SelectedEventCtx.Provider value={selectedEvent}>{children}</SelectedEventCtx.Provider>;
}
