import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icon";
import PttButton from "./PttButton";
import { useMonitoringPanelButtonStyle } from "../utils/styles";
import { autoHideStyle } from "./videoUIAutoHide";

type ItemProps = {
  key: CameraControlKey;
  icon: any;
  labelKey: StringKey;
  isProcessing: boolean;
  isDisabled: boolean;
  action?: Cb;
  Component?: ({ actionController }: { actionController: ActionController }) => JSX.Element | null;
};

type Props = {
  items: (ItemProps | null)[];
  actionController: ActionController;
  actionStates: ActionStates;
};

const Item = ({
  buttonHoverBg,
  buttonActiveBg,
  bg,
  isProcessing,
  isDisabled,
  icon,
  labelKey,
  action
}: Omit<ItemProps, "key"> & {
  buttonHoverBg: string;
  buttonActiveBg: string;
  bg: string;
}) => {
  const { t } = useTranslation();
  const label = t(labelKey);
  const isMultiWorded = label.includes(" ");
  return (
    <Flex
      direction="column"
      align="center"
      as="button"
      disabled={isDisabled}
      maxW="56px"
      _hover={{ ".icn-bg": { bg: buttonHoverBg } }}
      _active={{ ".icn-bg": { bg: buttonActiveBg } }}
      _disabled={{
        opacity: 0.4,
        boxShadow: "none",
        pointerEvents: "none"
      }}
      onClick={action}
    >
      <Flex p="12px" bg={bg} borderRadius="12px" className="icn-bg" transition="all 0.3s" backdropFilter="blur(8px)">
        {isProcessing ? <Spinner size="md" borderWidth="3px" /> : <Icon icon={icon} fill="neutral.99" />}
      </Flex>
      <Text
        mt="4"
        variant="label2"
        color="primary.99"
        maxW="100%"
        display="-webkit-box"
        textOverflow="ellipsis"
        overflow="hidden"
        sx={{
          WebkitLineClamp: isMultiWorded ? 2 : 1,
          WebkitBoxOrient: "vertical"
        }}
      >
        {label}
      </Text>
    </Flex>
  );
};

export default function BottomPanel({ items, actionController, actionStates }: Props) {
  const { getStyleObjAsValues } = useMonitoringPanelButtonStyle();

  const leftActions = items.slice(0, 2);
  const rightActions = items.slice(2);

  return (
    <Box pos="absolute" left="50%" bottom="12" transform="translateX(-50%)" {...autoHideStyle}>
      <Flex align="flex-start" columnGap="1rem">
        {leftActions.map((item) => {
          if (!item) return null;
          const { icon, key, labelKey, action, Component } = item;
          const isActive = actionStates[key].isActive;
          const isDisabled = actionStates[key].isDisabled;
          const isProcessing = actionStates[key].isProcessing;

          const { activeBg, bg, hoverBg } = getStyleObjAsValues(isActive);
          return (
            <Box key={key} pos="relative">
              {Component && <Component actionController={actionController} />}
              <Item
                bg={bg}
                isDisabled={isDisabled}
                isProcessing={isProcessing}
                buttonActiveBg={activeBg}
                buttonHoverBg={hoverBg}
                icon={icon}
                labelKey={labelKey}
                action={action}
              />
            </Box>
          );
        })}
        <PttButton />
        {rightActions.map((item) => {
          if (!item) return null;
          const { icon, key, labelKey, action, Component } = item;
          const isActive = actionStates[key].isActive;
          const isDisabled = actionStates[key].isDisabled;
          const isProcessing = actionStates[key].isProcessing;

          const { activeBg, bg, hoverBg } = getStyleObjAsValues(isActive);
          return (
            <Box key={key} pos="relative">
              {Component && <Component actionController={actionController} />}
              <Item
                bg={bg}
                isDisabled={isDisabled}
                isProcessing={isProcessing}
                buttonActiveBg={activeBg}
                buttonHoverBg={hoverBg}
                icon={icon}
                labelKey={labelKey}
                action={action}
              />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
