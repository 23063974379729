import { Flex, Button, Heading, FlexProps, Text } from "@chakra-ui/react";
import { t, openCameritoWeb } from "@/utils";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { useDevices } from "@/store/devices";
import { getCameraDevices } from "@/store/devices/selectors";
import AppImage from "@/components/AppImage";

export default function PremiumBanner({ stringKey, styles }: { stringKey: StringKey; styles?: FlexProps }) {
  const isPremium = useAuth(getHasActiveSubscription);
  const hasPairedCameras = useDevices(getCameraDevices).length > 0;

  if (isPremium || !hasPairedCameras) return null;
  return (
    <Flex
      alignItems="center"
      borderRadius="1rem"
      py="0.75rem"
      px="1rem"
      columnGap="0.75rem"
      w="100%"
      rowGap="0.75rem"
      bg="radial-gradient(144.26% 121.4% at 124.01% 125.81%, #00FFA3 0%, #0057CE 100%)"
      mb="1.5rem"
      flexDir={["column", "row", null]}
      flexWrap={["wrap", "nowrap", null]}
      {...styles}
    >
      <AppImage options={{ hasDarkMode: false, path: "icons" }} name="unlock.svg" />
      <Flex flexDir="column" flexGrow="1">
        <Heading as="h5" color="neutral.99" textAlign={["center", "start"]}>
          {t("unlockPremium")}
        </Heading>
        <Text color="neutral.99" opacity="0.8" textAlign={["center", "start"]}>
          {t("tryProVersion")}
        </Text>
      </Flex>

      <Button
        py="12"
        px="24"
        fontSize="md"
        borderRadius="24"
        fontWeight="medium"
        letterSpacing="0.5px"
        bg="neutral.99"
        color="primary.40"
        onClick={() => openCameritoWeb("pricing")}
      >
        {t(stringKey)}
      </Button>
    </Flex>
  );
}
