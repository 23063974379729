const msToSeconds = (ms: number) => ms / 1000;
const secondsToMs = (seconds: number) => seconds * 1000;

const pxToMs = (px: number) => secondsToMs(px);
const msToPx = (ms: number) => msToSeconds(ms);

const getEventInDateRange = (ranges: CameraEvent[], date: Date) => {
  try {
    let low = 0;
    let high = ranges.length;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const startDate = new Date(ranges[mid].start);
      const endDate = ranges[mid].finished ? new Date(ranges[mid].end) : new Date();

      if (startDate <= date && endDate >= date) {
        return ranges[mid];
      } else if (startDate > date) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }

    return false;
  } catch (err) {
    return false;
  }
};

const hashCode = (s: string) => {
  return s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const strictIsEqual = (compare: any, to: any) => {
  return JSON.stringify(compare) === JSON.stringify(to);
};

export { msToSeconds, secondsToMs, pxToMs, msToPx, getEventInDateRange, hashCode, strictIsEqual };
