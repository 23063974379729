import { Flex, Heading, Text, Button, chakra } from "@chakra-ui/react";
import { t, openEmailClient } from "@/utils";
import Icon from "@/components/Icon";
import NavigationBar from "@/components/NavigationBar";
import AppImage from "@/components/AppImage";
import CameritoIcon from "assets/icons/camerito.svg?react";
import GooglePlay from "assets/icons/store_googleplay.svg?react";
import AppStore from "assets/icons/store_appstore.svg?react";

type FlowState = {
  deviceId: "ios" | "android" | "desktop";
  isCamera: boolean;
  previousStepId: string;
};

const platformSpecifics = {
  ios: {
    headingKey: "pairing.help.install.ios.title",
    descriptionKey: "pairing.help.install.ios.description",
    storeIcon: AppStore
  },
  android: {
    headingKey: "pairing.help.install.android.title",
    descriptionKey: "pairing.help.install.android.description",
    storeIcon: GooglePlay
  },
  desktop: {
    headingKey: "pairing.help.desktop.launch",
    descriptionKey: "pairing.help.install.desktop.description",
    storeIcon: null
  }
} as const;

const EnhancedSpan = chakra("span");

export default function HowToInstall({ push, state, previous }: FlowProviding<FlowState>) {
  const { deviceId, isCamera, previousStepId } = state;

  const { headingKey, descriptionKey, storeIcon } = platformSpecifics[deviceId];
  return (
    <Flex grow={1} direction="column">
      <NavigationBar onBack={previous} props={{ my: "1rem" }} />

      <Flex flexGrow="1" px="5.5rem" direction="column" justify="center" align="center">
        <Heading as="h4" mb="16">
          {t(headingKey)}
        </Heading>

        <Flex mb="24" alignSelf="flex-start" display={deviceId === "desktop" ? "block" : "flex"} textAlign="center">
          {deviceId === "desktop" ? (
            <>
              {t(descriptionKey, {
                b: <EnhancedSpan fontWeight="bold" color="on-surface-variant" display="inline-block" />
              })}
              <Flex mt="24px" justify="center">
                <AppImage
                  name="desktop_search.png"
                  options={{
                    hasDarkMode: false,
                    imgProps: {
                      w: "316px",
                      h: "65px"
                    }
                  }}
                />
              </Flex>
            </>
          ) : (
            <Flex flexDir="column" alignItems="center">
              <Text>
                {t(descriptionKey, {
                  1: (
                    <Icon
                      style={{
                        display: "inline",
                        verticalAlign: "text-top",
                        width: "20px",
                        height: "20px"
                      }}
                      icon={storeIcon!}
                    />
                  )
                })}
              </Text>
              <Flex bg="secondary-container" alignItems="center" borderRadius="1rem" p="10" mt="24">
                <Icon icon={CameritoIcon} />
                <Text ml="12" fontWeight={600}>
                  {t("camerito.storeName")}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Button
        minW="18.5rem"
        mx="auto"
        mt="3rem"
        mb="0.5rem"
        onClick={() =>
          push(isCamera ? "how-to-pair" : previousStepId, {
            stepDirection: "previous"
          })
        }
      >
        {t("buttons.done")}
      </Button>
      <Button minW="18.5rem" mx="auto" variant="text" onClick={() => openEmailClient()}>
        {t("purchase.help.contactUs.heading")}
      </Button>
    </Flex>
  );
}
