import { throttle } from "@/utils";
import { useEffect, useMemo } from "react";

const className = "video-ui-auto-hide";
export const autoHideStyle = {
  className,
  transition: "opacity 0.3s",
  sx: {
    [`.hide-video-ui &.${className}`]: { opacity: "0 !important" }
  }
} as const;

export default function videoUIAutoHide() {
  let timeout: null | NodeJS.Timeout = null;
  const root = document.documentElement;

  const onMouseMove = throttle(() => {
    if (timeout) clearTimeout(timeout);
    document.querySelector("body")?.classList.remove("hide-video-ui");
    timeout = setTimeout(hideUI, 5000);
  }, 1000);

  const hideUI = () => {
    if (document.querySelector('[class*="chakra-modal"]')) return;
    document.querySelector("body")?.classList.add("hide-video-ui");
  };

  const start = () => {
    if (timeout) return;
    root.addEventListener("mousemove", onMouseMove);
    root.addEventListener("mousedown", onMouseMove);
    timeout = setTimeout(hideUI, 5000);
  };

  const stop = () => {
    if (timeout) clearTimeout(timeout);
    timeout = null;
    root.removeEventListener("mousemove", onMouseMove);
    root.removeEventListener("mousedown", onMouseMove);
  };

  return { start, stop };
}

export function useVideoUIAutoHide() {
  const uiAutoHide = useMemo(videoUIAutoHide, []);
  useEffect(() => {
    uiAutoHide.start();
    return uiAutoHide.stop;
  }, [uiAutoHide]);
}
