import { Box, Button, Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import NavigationBar from "@/components/NavigationBar";
import Lottie from "@/components/Lottie";
import onboardingAnimationLight from "assets/animations/onboarding2_light.json?url";
import onboardingAnimationDark from "assets/animations/onboarding2_dark.json?url";
import { WizardProps } from "../Wizard";

export default function HowTo({ next, push }: WizardProps) {
  const { t } = useTranslation();
  const animation = useColorModeValue(onboardingAnimationLight, onboardingAnimationDark);
  return (
    <Box>
      <NavigationBar onBack={() => push("add-camera", { stepDirection: "previous" })} props={{ my: "1rem" }} />
      <Flex w="100%" direction="column" justify="center" align="center" grow={1}>
        <Lottie src={animation} props={{ mb: "2rem" }} />
        <Heading as="h4" textAlign="center" px="32">
          {t("pairing.add.camera.step2")}
        </Heading>
        <Button mt="64" bg="primary" color="on-primary" py="18" minW="18.5rem" onClick={next}>
          {t("buttons.continue")}
        </Button>
      </Flex>
    </Box>
  );
}
