import { useState } from "react";
import { FlexProps, Heading, TextProps, Image } from "@chakra-ui/react";
import { useAuth } from "@/store/auth";
import Circle from "@/components/Circle";

export default function Avatar({
  size,
  textProps,
  circleProps
}: {
  size?: string;
  textProps?: TextProps;
  circleProps?: FlexProps;
}) {
  const pictureUrl = useAuth((state) => state.user?.pictureUrl);
  const [displayPlaceholder, setDisplayPlaceholder] = useState(!pictureUrl);
  const user = useAuth((state) => state.user);

  if (!user?.email) return null;

  return (
    <Circle bg="tertiary" size={size} props={{ ...circleProps }}>
      {pictureUrl && (
        <Image
          src={pictureUrl}
          alt="profile image"
          w="100%"
          h="100%"
          borderRadius="100%"
          display={displayPlaceholder ? "none" : "block"}
          onError={() => setDisplayPlaceholder(true)}
        />
      )}
      {displayPlaceholder && (
        <Heading as="h5" color="on-tertiary" {...textProps}>
          {user.email.charAt(0).toUpperCase()}
        </Heading>
      )}
    </Circle>
  );
}
