import { dataSyncEmitter } from "@/modules/events/emitter";
import { useEffect, useRef } from "react";
import { msToSeconds } from "./extensions";
import { TimelineHelpers } from "./useTimelineHelpers";
import { RefList } from "./useTimelineUpdater";

type Props = {
  refs: RefList;
  TH: TimelineHelpers;
  sync: Cb;
};

export const useTimelineVisuals = ({ refs, TH, sync }: Props) => {
  const { timelineStartDate, timeline, video, backgroundSvg, videoInfo } = refs;
  const prevRotation = useRef<ImageRotation>("ROTATION_0");
  const imageRotations = useRef<{ startTime: number; rotation: ImageRotation }[]>([]);

  const addImageRotation = (e: Event) => {
    const { detail } = e as TimelineFileEvent;
    const { date, imageRotation } = detail;
    if (!imageRotation) return;
    const newImageRotation = { startTime: new Date(date).getTime(), rotation: imageRotation };
    const alreadyExists = imageRotations.current.findIndex((i) => i.startTime === newImageRotation.startTime) !== -1;
    if (alreadyExists) return;

    imageRotations.current.push(newImageRotation);
    imageRotations.current.sort((a, b) => a.startTime - b.startTime);
  };

  const rotatePlayerBasedOnImageRotation = () => {
    const currentTime = TH.getCurrentPlayerDateTime();
    let currentRotation = prevRotation.current;
    imageRotations.current.forEach(({ rotation, startTime }) => {
      if (currentTime >= startTime) currentRotation = rotation;
    });

    const videoEl = refs.video.current;
    if (currentRotation !== prevRotation.current && videoEl) {
      const aspectRatio = videoEl.videoWidth / videoEl.videoHeight;
      const disableManualRotation =
        (aspectRatio > 1 && (currentRotation === "ROTATION_180" || currentRotation === "ROTATION_0")) ||
        (aspectRatio < 1 && (currentRotation === "ROTATION_90" || currentRotation === "ROTATION_270"));

      if (currentRotation === "ROTATION_0" || currentRotation === "ROTATION_180")
        dataSyncEmitter.emit("player-orientation-change", {
          isEnabled: !disableManualRotation,
          orientation: "landscape"
        });
      else
        dataSyncEmitter.emit("player-orientation-change", {
          isEnabled: !disableManualRotation,
          orientation: "vertical"
        });

      prevRotation.current = currentRotation;
    }
  };

  const adjustBackgroundSvgPosition = () => {
    if (backgroundSvg.current)
      backgroundSvg.current.style.backgroundPositionX = `-${
        (new Date(timelineStartDate.current!).getTime() / 1000) % 60
      }px`;
  };

  const moveTimeline = () => {
    if (!video.current || !timeline.current) return;
    sync();

    const position = convertTimeToPosition(video.current.currentTime, {
      min: msToSeconds(videoInfo.current.timeDeletedInMs),
      max: videoInfo.current.duration
    });

    adjustBackgroundSvgPosition();
    timeline.current.style.left = `-${Math.floor(position)}px`;
  };

  const convertTimeToPosition = (time: number, constraints: { min: number; max: number }) => {
    const timeMin = constraints.min;
    const timeMax = constraints.max;
    const positionMin = 0;
    const positionMax = videoInfo.current.width;

    const position = ((time - timeMin) / (timeMax - timeMin)) * (positionMax - positionMin) + positionMin;
    const constrained = Math.min(Math.max(position, positionMin), positionMax);
    return constrained;
  };

  useEffect(() => {
    document.addEventListener("hls-init-file", addImageRotation);
    return () => {
      document.removeEventListener("hls-init-file", addImageRotation);
    };
  }, []);

  return {
    moveTimeline,
    rotatePlayerBasedOnImageRotation
  };
};
