import { Box, BoxProps } from "@chakra-ui/react";
import lottie, {
  AnimationConfigWithPath,
  AnimationItem,
  AnimationEventName,
  AnimationConfigWithData
} from "lottie-web";
import { useEffect, useRef } from "react";

type Props = {
  src?: string;
  props?: BoxProps;
  options?: Omit<AnimationConfigWithPath, "path" | "container"> | Omit<AnimationConfigWithData, "container">;
  events?: [AnimationEventName, Cb][];
  getAnimationItem?: (item: AnimationItem) => void;
};

export default function Lottie({ src, props, options, events, getAnimationItem }: Props) {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animationOptions: AnimationConfigWithPath = {
      container: animationContainer.current as HTMLDivElement,
      path: src,
      ...options
    };
    const animation = lottie.loadAnimation(animationOptions);
    getAnimationItem?.(animation);

    events?.forEach(([event, callback]) => {
      if (!event || !callback) return;
      animation.addEventListener(event, callback);
    });
    return () => {
      events?.forEach(([event, callback]) => {
        animation.removeEventListener(event, callback);
      });
      animation.destroy();
    };
  }, [getAnimationItem, options, src, events]);

  return <Box ref={animationContainer} {...props} />;
}
