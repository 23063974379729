import { useCallback, useRef, MouseEvent as ReactMouseEvent, MutableRefObject, RefObject, useMemo } from "react";
import { addGuard } from "@/utils";
import { msToSeconds } from "./extensions";
import { RefList } from "./useTimelineUpdater";
import { INDICATOR_PX_WIDTH } from "./const";
import { EventHelpers } from "./useEvents";

type Props = {
  refs: RefList;
  updateMetaTime: Cb;
  Events: EventHelpers;
  ignoreNextTimeUpdate: MutableRefObject<boolean>;
};

const SEEK_INTERVAL = 500;

export const useSeeking = ({ refs, updateMetaTime, Events, ignoreNextTimeUpdate }: Props) => {
  const { timeline, video, videoInfo, playerRef, indicator } = refs;
  const isDragging = useRef(false);
  const mouseX = useRef<null | number>(null);
  const initialMouseX = useRef<null | number>(null);
  const wasPausedBeforeSeek = useRef(false);
  const seekIntervalControl = useRef<{ cb: ((p?: boolean) => void) | null; interval: NodeJS.Timeout | null }>({
    cb: null,
    interval: null
  });
  const scrollableContainer = useRef<HTMLDivElement | null>(null);
  const scrollableElement = useRef<HTMLDivElement | null>(null);
  const maxScrollPosition = useRef(0);
  const defaultScrollPosition = useRef(0);

  const convertPositionToTime = useCallback(
    (positionInPx: number, constraints: { min: number; max: number }) => {
      const positionMin = constraints.min;
      const positionMax = constraints.max;
      const timeMin = msToSeconds(videoInfo.current.timeDeletedInMs) + 10;
      const timeMax = videoInfo.current.duration;

      const time = ((positionInPx - positionMin) / (positionMax - positionMin)) * (timeMax - timeMin) + timeMin;
      const constrained = Math.min(Math.max(time, timeMin), timeMax);
      return constrained;
    },
    [videoInfo]
  );

  const getCurrentSeekPosition = () => {
    const { left, width } = document.querySelector("#timeline-container")!.getBoundingClientRect();
    return left + width / 2;
  };

  const getIndicatorTimeInSec = useCallback(() => {
    const { left } = timeline.current!.getBoundingClientRect();
    const width = videoInfo.current.width;
    const indicatorRect = indicator.current!.getBoundingClientRect();
    const position = indicatorRect.left + INDICATOR_PX_WIDTH / 2 - left;
    const time = convertPositionToTime(position, { min: 10, max: width });
    return time;
  }, [convertPositionToTime, indicator, timeline, videoInfo]);

  const setCurremtTime = useCallback(() => {
    const time = getIndicatorTimeInSec();
    playerRef.current?.currentTime(Math.ceil(time));
  }, [getIndicatorTimeInSec, playerRef]);

  const onMouseMove = (e: MouseEvent) => (mouseX.current = e.pageX);
  const preventTimelineAutoMovement = useCallback(() => (ignoreNextTimeUpdate.current = true), [ignoreNextTimeUpdate]);

  const stopDrag = useCallback(
    (e?: MouseEvent) => {
      const wasAClick = e?.pageX === initialMouseX.current;
      if (seekIntervalControl.current.cb && seekIntervalControl.current.interval) {
        clearInterval(seekIntervalControl.current.interval);
        if (!wasAClick) seekIntervalControl.current.cb();
        seekIntervalControl.current.interval = null;
        seekIntervalControl.current.cb = null;
      }

      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", stopDrag);
      isDragging.current = false;
      if (!wasPausedBeforeSeek.current) addGuard(() => video.current!.play(), { onError: (err) => log.err(err) });

      if (wasAClick) {
        const seekPosition = getCurrentSeekPosition();
        const timelineRect = timeline.current!.getBoundingClientRect();
        const width = videoInfo.current.width;
        const seekDistance = e.pageX - seekPosition;

        const distance = seekPosition - timelineRect.left + seekDistance - 2;

        timeline.current!.style.left = `${Math.ceil(Math.min(Math.max(distance, 0), width - 2)) * -1}px`;

        preventTimelineAutoMovement();
        setCurremtTime();
        Events.updateMetaEventOnSeek();
        updateMetaTime();
      }

      initialMouseX.current = null;
      mouseX.current = null;
      scrollDistance.current = 0;
    },
    [Events, preventTimelineAutoMovement, setCurremtTime, timeline, updateMetaTime, video, videoInfo]
  );

  const startDrag = useCallback(
    (e?: ReactMouseEvent, isScrolling?: boolean) => {
      wasPausedBeforeSeek.current = video.current?.paused || false;
      if (!video.current?.paused) video.current?.pause();

      initialMouseX.current = isScrolling ? 0 : e?.pageX || 0;
      const timelineRect = timeline.current!.getBoundingClientRect();
      const width = videoInfo.current.width;

      const seekPosition = getCurrentSeekPosition();
      const initialDistance = seekPosition - timelineRect.left;

      const animateDrag = () => {
        if (!timeline.current) return;
        if (!isDragging.current) return;

        const mouseMovement = isScrolling
          ? scrollDistance.current * -1
          : mouseX.current
          ? mouseX.current - e?.pageX!
          : 0;
        let seekDistance = initialDistance - mouseMovement;

        seekDistance = Math.min(Math.max(seekDistance, 10), width);

        timeline.current.style.left = `${seekDistance * -1}px`;

        if (isDragging.current) requestAnimationFrame(animateDrag);
        else mouseX.current = null;
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", stopDrag);
      isDragging.current = true;
      requestAnimationFrame(animateDrag);

      seekIntervalControl.current.cb = () => {
        preventTimelineAutoMovement();
        setCurremtTime();
        Events.updateMetaEventOnSeek();
        updateMetaTime();
      };
      seekIntervalControl.current.interval = setInterval(seekIntervalControl.current.cb, SEEK_INTERVAL);
    },
    [Events, preventTimelineAutoMovement, setCurremtTime, stopDrag, timeline, updateMetaTime, video, videoInfo]
  );

  const setScrollableElements = ({
    containerEl,
    scrollEl
  }: {
    containerEl: RefObject<HTMLDivElement>;
    scrollEl: RefObject<HTMLDivElement>;
  }) => {
    scrollableContainer.current = containerEl.current;
    scrollableElement.current = scrollEl.current;
    if (scrollableContainer.current) {
      scrollableContainer.current.scrollLeft = 1;
    }
  };

  const maxScroll = useRef(0);
  const minScroll = useRef(0);
  const setScrollableValues = useCallback(() => {
    if (!timeline.current || !indicator.current || !scrollableContainer.current || !scrollableElement.current) return;
    if (maxScroll.current && minScroll.current) {
      return {
        maxScrollBound: maxScroll.current,
        minScrollBound: minScroll.current
      };
    }

    const { left } = timeline.current.getBoundingClientRect();
    const width = videoInfo.current.width;
    const indicatorRect = indicator.current.getBoundingClientRect();
    const position = indicatorRect.left - left;

    minScroll.current = position * -1;
    maxScroll.current = width - position;

    const containerWidth = Math.floor(scrollableContainer.current.getBoundingClientRect().width);
    const elementWidth = Math.floor(scrollableElement.current.getBoundingClientRect().width);
    maxScrollPosition.current = elementWidth - containerWidth;
    defaultScrollPosition.current = Math.floor(elementWidth / 3.33);
    scrollableContainer.current.scrollLeft = defaultScrollPosition.current;
    previousScrollPosition.current = defaultScrollPosition.current;

    return {
      maxScrollBound: maxScroll.current,
      minScrollBound: minScroll.current
    };
  }, [indicator, timeline, videoInfo]);

  const ignoreFirstScrollEvent = useRef(true);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const previousScrollPosition = useRef(0);
  const scrollDistance = useRef(0);

  const clearScrollTimeout = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = null;
    }
  };

  const onScroll = useCallback(() => {
    if (!scrollableContainer.current) return;
    if (ignoreFirstScrollEvent.current) {
      ignoreFirstScrollEvent.current = false;
      return;
    }
    const bounds = setScrollableValues();
    if (!bounds) return;
    const { maxScrollBound, minScrollBound } = bounds;

    const scrollPosition = scrollableContainer.current.scrollLeft;
    const defaultScrollPos = defaultScrollPosition.current;

    if (scrollPosition <= 0) {
      scrollDistance.current -= 1;
      previousScrollPosition.current = defaultScrollPos;
      scrollableContainer.current.scrollLeft = defaultScrollPos;
    } else if (scrollPosition >= maxScrollPosition.current) {
      scrollDistance.current += 1;
      previousScrollPosition.current = defaultScrollPos;
      scrollableContainer.current.scrollLeft = defaultScrollPos;
    } else {
      const scrollDifference = Math.abs(previousScrollPosition.current) - Math.abs(scrollPosition);

      previousScrollPosition.current = scrollPosition;
      scrollDistance.current -= scrollDifference;
      scrollDistance.current = Math.min(Math.max(scrollDistance.current, minScrollBound), maxScrollBound);
    }

    if (!isDragging.current) startDrag(undefined, true);
    if (scrollTimeout.current) clearScrollTimeout();
    scrollTimeout.current = setTimeout(() => {
      clearScrollTimeout();
      stopDrag();
      maxScroll.current = 0;
      minScroll.current = 0;
    }, SEEK_INTERVAL / 2);
  }, [setScrollableValues, startDrag, stopDrag]);

  return useMemo(
    () => ({
      startDrag,
      onScroll,
      setScrollableElements
    }),
    [onScroll, startDrag]
  );
};
