import { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { t } from "@/utils";
import { textOverflowDots } from "@/theme/utils/style";
import Time from "@/lib/Time";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";

const ONE_DAY_IN_MS = 86400000;

type OngoingEvents = {
  [K in RepeatedTimeEvent]: boolean;
};
type EventStringKey =
  | (typeof stringKeys)["MOTION"]["active"]
  | (typeof stringKeys)["MOTION"]["finished"]
  | (typeof stringKeys)["NOISE"]["active"]
  | (typeof stringKeys)["NOISE"]["finished"];

const stringKeys = {
  MOTION: {
    active: "motionDetection.now",
    finished: "monitor.lastMotion"
  },
  NOISE: {
    active: "noiseDetection.now",
    finished: "parentStation.monitor.lastNoise"
  }
} as const;

export default function LastActivity({
  preview,
  isAvailable
}: {
  preview: CameraPreviewState[string];
  isAvailable: boolean;
}) {
  const [lastIntervalEvent, setLastIntervalEvent] = useState<CameraEvent | null>(null);
  const isPremium = useAuth(getHasActiveSubscription);

  const [ongoingEvents, setOngoingEvents] = useState<OngoingEvents>({
    MOTION: false,
    NOISE: false
  });

  useEffect(() => {
    const event = preview?.status?.lastEvent;
    if (!event || !event.intervalEvent) {
      setLastIntervalEvent(null);
      return;
    }
    if (event.finished) {
      setOngoingEvents((prev) => ({ ...prev, [event.type]: false }));
      setLastIntervalEvent(event);
    } else if (!event.finished) {
      setOngoingEvents((prev) => ({ ...prev, [event.type]: true }));
    }
  }, [preview]);

  if (!isAvailable) return <Text>{t("camera.offline")}</Text>;

  let stringKey: EventStringKey | "noEventsRecently" = "noEventsRecently";
  if (ongoingEvents.MOTION) stringKey = stringKeys["MOTION"].active;
  else if (ongoingEvents.NOISE) stringKey = stringKeys["NOISE"].active;
  else if (lastIntervalEvent && lastIntervalEvent.end) {
    const activitylogSaveDuration = isPremium ? 21 * ONE_DAY_IN_MS : ONE_DAY_IN_MS;
    const isEventInActivityLog = Date.now() - lastIntervalEvent.end < activitylogSaveDuration;
    if (isEventInActivityLog) stringKey = stringKeys[lastIntervalEvent.type as RepeatedTimeEvent].finished;
  }

  return (
    <Text {...textOverflowDots}>
      {t(stringKey, {
        1: Time.fromNow(lastIntervalEvent?.end || 0)
      })}
    </Text>
  );
}
